/* FooterStyles.css */

.footer-container {
  border-radius: 0;
  background-color: var(--vt-maroon); /* Change this to your maroon color */
  max-width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 1rem;
  padding-left: 1rem;
}

.footer-logo {
  height: auto;
  max-width: 20%;
  padding-top: 10px;
}

.footer-links {
  display: flex;
  justify-content: space-between;
}

.footer-links a {
  color: white;
  font-size: 1.5rem;
  margin-right: 1rem;
  text-decoration: none;
}

.footer-copyright {
  color: white;
  padding-left: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.footer-line-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.social-icon {
  color: white;
  text-decoration: none;
  font-size: 1.5rem;
  margin-right: 0.5rem;
  padding-bottom: 1rem;
}

.social-icon-container {
  display: flex;
}

/* Media Queries for Mobile Screens */
@media (max-width: 767px) {
  .footer-logo {
    max-width: 50%; /* Adjust the max-width for smaller screens */
  }

  .footer-links {
    flex-direction: column; /* Stack links vertically on small screens */
    align-items: center;
  }

  .footer-links a {
    margin-right: 0; /* Remove right margin for better spacing */
    margin-bottom: 0.5rem;
    font-size: 1.25rem;
  }

  .footer-copyright {
    text-align: center; /* Center copyright text on small screens */
  }

  .footer-line-container {
    flex-direction: column; /* Stack content vertically on small screens */
    align-items: center;
  }

  .social-icon {
    margin-left: 0.1;
    margin-right: 0.1;
    margin-bottom: 0.5rem;
  }
}
