.historycard_container {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 20px;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  margin: 10px;
}

.historycard_image_container {
  display: flex;
  align-items: center;
}

.historycard_image {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
  object-fit: cover;
  margin-bottom: 10px;
}

.historycard_stat {
  margin-bottom: 10px;
  font-size: 14px;
}

@media(max-width: 1260px) {
  .historycard_container {
    grid-template-columns: 1fr;
    margin: 0px;
  }
}