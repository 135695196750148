.sponsor_contact_card_form_container {
  outline-style: solid;
  border: 1px solid #000000; 
  border-radius: 20px;
}

.sponsor_contact_card_title {
  text-align: center;
}

.sponsor_contact_card_info_form_short_box {
  border-radius: 5px;
  width: 100%; 
  height: 80%;
  border: 1px solid #000000; 
  padding: 15px;
}

.sponsor_contact_card_info_form_large_box {
  border-radius: 5px;
  width: 100%; 
  height: 150px; 
  border: 1px solid #000000; 
  padding: 15px;
}

.sponsor_contact_card_submit_button{
  width: 175px;
  height: 40px;
  border: none;
  border-radius: 6px;
  background-color: #861F41; /* Maroon background color */
  color: white;
  cursor: pointer;
  margin-top: 0px;
  margin-bottom: 15px;
}