.teamcard_container {
  outline-style: blank;
  border-radius: 20px;
  padding: 15px 15px 15px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  width: 400px;
  height: 675px;
  margin: 20px;
}

.teamcard_image {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  object-fit: cover; /* This will ensure the image maintains its aspect ratio */
  object-position: center; /* This will center the image in the container */
}

.teamcard_info_container {
  padding: 15px;
  text-align: center;
}

.teamcard_name {
  font-size: 1em;
  margin: 0;
}

.teamcard_position {
  font-size: 1em;
  margin: 0;
}

.teamcard_year {
  font-size: 1em;
  margin: 0;
}