/*
Site wide colors from Virginia Tech
*/
:root {
  /* Primary colors */
  --vt-maroon:#861f41;
  --vt-burntOrange:#e5751f;
  --vt-hokieStone: #75787b;
  --vt-white: #ffffff;

  /* Secondary colors */
  --vt-purple: #642667;
  --vt-pink: #ce0058;
  --vt-yellow: #f7ea48;
  --vt-teal: #508590;
  --vt-turquoise: #2cd5c4;
  --vt-grey: #d7d2cb;
  --vt-smoke: #e5e1e6;
  --vt-impactOrange: #ca4f00;
}

/* 
* general
*/
* {
  margin: 0;
  padding: 0;
  text-decoration: none;
  box-sizing: border-box;
}

body,
html {
  height: 100%;
  margin: 0;
}

.page_title {
  padding-left: 10px;
  display: flex;
  width: 100%;
  padding-bottom: 10px;
  justify-content: center;
}

.edit {
  padding-top: 5px;
  padding-left: 20px;
}

.edit :hover {
  cursor: pointer;
}

/**
* Submit button css
*/
.submit_button {
  width: 175px;
  height: 40px;
  border: none;
  border-radius: 6px;
  background-color: #293856;
  color: white;
  cursor: pointer;
  margin-top: 40px;
}



/* 
* main container
*/
.main_page_container {
  padding-top: 0px;
  outline-style: blank;
  outline-color: #E5751F;
  border-radius: 20px;
  padding: 10px 10px;
  margin: 15px 15px 15px 15px;
  flex: 1;
  flex-direction: column;
}

/**
* Sponsorship page css
*/
.sponsor_grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.sponsor_reg_button_container {
  display: flex;
  justify-content: center;
  margin-top: 20px; /* Adjust as needed */
}

.sponsor_reg_button {
  width: 100%;
  padding: 20px; /* Increase padding for a larger button */
  background-color: #861F41;
  color: white; /* Change text color to black */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 32px; /* Adjust font size as needed */
  font-weight: bold; /* Make text bold */
  text-align: center;
  text-decoration: none;
  display: inline-block;
}


/**
* Gallery css
*/
.gallery_grid {
  display:grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  padding: 20px;
  justify-content: center;
  align-items: center;
}

.delete_gallery_button {
  padding: 10px 20px;
  margin-top: 10px;
  background-color: #861F41;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.delete_gallery_button:hover {
  background-color: #720F2C;
}

/* Gallery column size responsive adjustments */

/* Medium sized screens 2 columns*/
@media (max-width: 768px){
  .gallery_grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* Small Screens 1 column */
@media (max-width: 480px){
  .gallery_grid {
    grid-template-columns: repeat(1, 1fr);
  }
}


/* Team page css */
.team_grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

/* 
* History css
*/
.history_card{
  outline-style: solid;
  outline-color: rgba(0, 0, 0, 0.3);
  border-radius: 20px;
  padding: 15px 15px 15px 15px;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 10px;
  flex-direction:column;
  align-items:center;
}

/*
* Handle size of image in modal
*/
.image_preview {
  width: 450px;
}

.form_label {
  padding-bottom: 20px;
}


/* Header css */
.header-container {
  width: 100%;
  margin-top: 0;
  position: relative;
  height:5%;
  display: grid;
  grid-template-columns: 15% 70% 15%; /* left cell for logo, middle cell for title, right cell empty */
  align-items: center; /* Align items vertically in the center */
  padding: 10 10px; /* Remove padding above and below, keep horizontal padding */
  margin-bottom: 0px;
}

.logo-container {
  grid-column: 1 / 2; /* Place the logo in the first column */
}

.title-container {
  grid-column: 2 / 3; /* Place the title in the second column */
  justify-self: center; /* Center the title horizontally */
  display: flex; /* Use flexbox for vertical centering */
  align-items: center; /* Center the title vertically */
}

.title-link {
  text-decoration: none;
  color: inherit;
  cursor: pointer; /* Change cursor to pointer on hover */
  display: flex; /* Use flexbox for better control of the content */
  align-items: center; /* Align text vertically */
  justify-content: center; /* Align text horizontally */
  width: 100%; /* Ensure the link takes up the entire width of its container */
}

.title-link:hover, .title-link:focus {
  text-decoration: none;
  color: inherit; /* Prevent color change on hover and focus */
}

.website-title {
  margin: 0; /* Remove default margins */
  text-align: center; /* Center text horizontally */
  font-size: 40px;
}

/* 
* home
*/
.home-container {
  padding: 0px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  background-image: url("./Assets/home.jpg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}

.home-header {
  padding-top: 20px;
  padding-left: 20px;
}

.home_text_container {
  padding-left: 20px;
}


.rotating_images{
  position: relative;
  /* add size  */
}

.home_centered_text {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center;
}

.home-top-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  text-align: center;
  color: var(--vt-white);
  background-color: var(--vt-grey);
  border-bottom: 10px solid var(--vt-maroon);
}

.home-top-image {
  width: 100%;
  opacity: 0.25;
}

.home-top-text {
  position: absolute;
  font-size: 4em;
  max-width: 100%;
  color: black;
}

@media(max-width: 830px) {
  .home-top-image {
    object-fit: cover;
    width: auto;
    height: 200px;
    max-width: 100%;
  }

  .home-top-text {
    font-size: 2em;
  }
}

.home-image-info-container {
  width: 75%;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  background-color: var(--vt-hokieStone);
  margin: 50px 50px;
  padding: 25px 25px;
  border-radius: 50px;
}

.home-image-half {
  border-radius: 10px;
  object-fit: contain;
  object-position: center;
  max-width: 500px;
  width: 100%;
  margin-bottom: 25px;
}

.home-info-half-container {
  width: 500px;
  display: flex;
  align-items: center;
}

.home-info-half {
  color: var(--vt-white);
  font-size: large;
}

/* Membership page css */

.membership_main_container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.membership_left {
  flex: 1;
  display: flex;
  height: 100%;
  padding: 15px 15px 15px 15px;
  justify-content: center;
  align-items: center;
  min-width: 400px;
}

.membership_right {
  flex: 1;
  display: flex;
  height: 100%;
  padding: 15px 15px 15px 15px;
  justify-content: center;
  align-items: center;
  
}

.membership_centered_text {
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center;
}

/* SponsorRegistration page css */

.sponsor_reg_main_container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.sponsor_reg_left {
  flex: 1;
  display: flex;
  height: 100%;
  padding: 15px 15px 15px 15px;
  justify-content: center;
  align-items: center;
  min-width: 400px;
}

.sponsor_reg_right {
  flex: 1;
  display: flex;
  height: 100%;
  padding: 15px 15px 15px 15px;
  justify-content: center;
  align-items: center;
}

.sponsor_reg_centered_text {
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center;
}


/* FaqPage.css */
.faq-style-wrapper {
  margin-top: 2em;
}

.faq-style-wrapper .faq-title {
  font-size: x-large;
}

.faq-style-wrapper .faq-body .faq-row {
  padding: 0.75em 0;
}

.faq-style-wrapper .faq-body .faq-row .row-title {
  font-size: large;
  color: grey;
}

.faq-style-wrapper .faq-body .faq-row .row-content-text {
  padding: 2em !important;
  font-size: Large !important;
}

/* Additional styles for a clean layout */
body {
  margin: 0;
  font-family: Arial, sans-serif;
}

/* You can customize the background color and other styles as needed */
body {
  background-color: #ffffff; /* Solid white background */
}


/* PageNotFound.css */

.page-not-found-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  min-height: 66.5vh;
  background-color: #f0f0f0;
}

.page-not-found-container h1 {
  font-size: 2em;
  margin-bottom: 10px;
  text-align: center;
}

.page-not-found-container p {
  font-size: 1.2em;
  margin-bottom: 20px;
  text-align: center;
}

.page-not-found-container a {
  color: #007bff;
  text-decoration: none;
  font-weight: bold;
}
