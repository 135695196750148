.sponsorcard_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 20px;
  padding: 15px 15px 15px 15px;
  outline-style: blank;
  margin: 10px;
  position: relative;
  width: 300px;
}

.sponsorcard_image {
  height: 120px;
  width: 100%;
  margin-right: 20px;
  border-radius: 10px;
  object-fit: contain; /* This will ensure the image maintains its aspect ratio */
  object-position: center; /* This will center the image in the container */
}

.sponsorcard_title_container {
  width: 100%;  
}

.sponsorcard_title {
  font-size: 2em;
  margin-top: 10px; 
  text-align: center; /* Ensure text is centered within the button */
}