.main-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 95%;
}

.topbar-header {
	position: relative;
}

.topbar-logo {
  max-height: 100px; /* Make the logo expand to fill the container */
  height: auto; /* Maintain aspect ratio */
  padding-left: 2px;
  padding-top: 2px;
}

.topbar-container {
	margin: auto;
}

.topbar {
  outline-style: blank;
  display: flex;
  flex-wrap: wrap;
  align-items: center; /* Centers the items vertically */
  justify-content: space-between;
  text-align: center;
  position: relative;
  background-color: var(--vt-maroon);
}

.nav {
  height: 100px;
}

#nav-check{
  position: absolute;
	opacity:0;
	pointer-events: none;
}

.nav-toggler{
	height: 34px;
	width: 44px;
	background-color: #ffffff;
	border-radius: 4px;
	cursor: pointer;
	display: none;
	align-items: center;
	justify-content: center;
	margin-right: 15px;
}
.nav-toggler span{
	display: block;
	height: 2px;
	width: 20px;
	background-color: var(--vt-maroon);
	position: relative;
}
.nav-toggler span::before,
.nav-toggler span::after{
   content: '';
   position: absolute;
   width: 100%;
   height: 100%;
   background-color: var(--vt-maroon);
   left:0;
}
.nav-toggler span::before{
	top: -6px;
}
.nav-toggler span::after{
	top: 6px;
}

@media(max-width: 991px) {
  .nav-toggler{
    display: flex;
  }
  .nav {
    position: absolute;
    left:0;
    top:100%;
    width: 100%;
    background-color: var(--color-1);
    border-top: 1px solid rgba(255,255,255,0.2);
    opacity:0;
    visibility: hidden;
  }
  .link {
    width: 100%;
  }
  .link_text {
    display: block;
    margin:0;
  }

  #nav-check:checked ~ .nav{
    opacity: 1;
    visibility: visible;
    z-index: 1;
  }

  #nav-check:focus ~ .nav-toggler{
    box-shadow: 0 0 15px #000000;
  }

  #nav-check:checked ~ .nav-toggler span{
    background-color: transparent;
  }

  #nav-check:checked ~ .nav-toggler span::before{
    top:0;
    transform: rotate(45deg);
  }

  #nav-check:checked ~ .nav-toggler span::after{
    top:0;
    transform: rotate(-45deg);
  }
}



.link {
  display: flex;
  padding: 5px 25px;
  color: white;
  align-items: center;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  background-color: var(--vt-maroon); /* Original background color */
  transition: background-color 0.2s; /* Smooth transition for hover effect */
}

.link:hover {
  text-decoration: none;
  background-color: #5f132c; /* Darker shade for hover */
  color: white; /* Keep text color white on hover */
}

.active {
  background-color: var(--vt-maroon);
}

.icon {
  flex: 30%;
  display: grid;
  place-items: center;
}

.link-text {
  flex: 70%;
}